import request, {
  SERVICE_MODULE_MAP
} from "@/utils/request";


export class StockGroupApi {

  //获取库存信息 
  getStockInfo(params) {
    return request({
      baseURL: SERVICE_MODULE_MAP.supportModule,
      url: '/settings/company-warehouse-groups',
      method: 'get',
      params,
    });
  }

  //获取仓库list 
  getWarehouse(params) {
    return request({
      baseURL: SERVICE_MODULE_MAP.coreModule,
      url: '/stocks/company-warehouse-stocks/warehouses',
      method: 'get',
      params,
    });
  }

  //删除仓库list 
  deleteWarehouse(data) {
    return request({
      baseURL: SERVICE_MODULE_MAP.supportModule,
      url: '/settings/company-warehouse-groups/delete',
      method: 'post',
      data,
    });
  }

  //新增 仓库list 
  insertWarehouse(data) {
    return request({
      baseURL: SERVICE_MODULE_MAP.supportModule,
      url: '/settings/company-warehouse-groups',
      method: 'post',
      data,
    });
  }

  //编辑修改仓库list
  updateWarehouse(data) {
    return request({
      baseURL: SERVICE_MODULE_MAP.supportModule,
      url: '/settings/company-warehouse-groups/' + data.id,
      method: 'post',
      data,
    });
  }
}